import { create } from 'zustand';

interface Connection {
  status: boolean;
  setStatus: (status: boolean) => void;
}

export const useConnectionStatusStore = create<Connection>((set) => ({
  status: false,
  setStatus: (status: boolean) => set({ status }),
}));
