import React, { FC, useEffect } from "react";
import Modal from "react-modal";
import Button from "../../pages/Home/components/Button/Button";
import { useConnectionStatusStore } from "../../stores/useConnectionStatus";
import styles from "./modal.module.scss";
export const MyModal: FC = () => {
  const connectionStatus = useConnectionStatusStore((state) => state.status);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "300px",
    },
  };

  const [modalIsOpen, setIsOpen] = React.useState(connectionStatus);

  useEffect(() => {setIsOpen(connectionStatus)}, [connectionStatus])

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      {typeof modalIsOpen !== null &&
				<Modal
					isOpen={modalIsOpen}
					onRequestClose={closeModal}
					style={customStyles}
					contentLabel="Connection lost"
				>
					<div className={styles.info_wrapper}>
						<div className={styles.title} >Connection with socket lost</div>
						<div className={styles.title} >Refresh the page</div>
					</div>
					<div className={styles.action_wrapper}>
						<div><Button onClick={() => window.location.reload()} titleBtn="Refresh"/></div>
					</div>
				</Modal>}
    </div>
  );
};
