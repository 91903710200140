import React, { FC, useEffect } from "react";

// import menu from "../../assets/menu.svg";
// import microphone from "../../assets/microphone.svg";
import logout from "../../assets/logout.svg";
// import { useCallInfoStore } from "../../stores/useCallInfo";

// import { useOnClickOutside } from "../../utils/hooks/useClickOutside";
import { MyModal } from "../Modal/Modal";
import styles from "./layout.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { WindowFocusHandler } from "../../utils/hooks/useWindowFocus";
import { useOperatorProfileStore } from "../../stores/useOperatorProfile";
// import { BottomInfo } from "../BottomInfo/BottomInfo";
import logo from "../../assets/logoWithText.svg";
import closeNew from "../../assets/closeNew.svg";

type LayoutProps = {
  children?: React.ReactNode;
};

export const Layout: FC<LayoutProps> = ({ children }) => {
  // const [isOpen, setIsOpen] = useState(false);
  // const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const refreshProfile = useOperatorProfileStore((state) => state.refreshProfile);
  const getOperator = useOperatorProfileStore((state) => state.getProfile);
  const operator = useOperatorProfileStore((state) => state.operatorProfile);
  // const operatorCompany = useOperatorProfileStore((state) => state.operatorProfile.org_name);

  useEffect(() => {
    if (!operator) {
      getOperator();
    }
  }, []);

  // useOnClickOutside(dropdownRef, () => setIsOpen(false));

  const logOut = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    refreshProfile();
    navigate("/login");
  };

  // const isAuth = localStorage.getItem("token") || sessionStorage.getItem("token");
  // const micURL = "https://mic." + window.location.hostname;

  return (
    <div className={styles.container}>
      <header>
        <div className={`${styles.contentWrapper} ${styles.headerContent}`}>
          <div className={styles.infoWrapper}>
            {/*<Link to={micURL} target="_blank" rel="noopener noreferrer">*/}
            <img className={styles.logoApp} src={logo} alt="logo"/>
            <div className={styles.logoWrapper}>AI</div>
            {/*</Link>*/}
          </div>
          {/*{isAuth && (*/}
          {/*  <div*/}
          {/*    ref={dropdownRef}*/}
          {/*    onClick={() => setIsOpen(!isOpen)}*/}
          {/*    className={styles.menuWrapper}*/}
          {/*  >*/}
          {/*    <img className={styles.menu} src={menu} alt="menu"/>*/}
          {/*    {isOpen && (*/}
          {/*      <div onClick={logOut} className={styles.btnLog}>*/}
          {/*        Logout*/}
          {/*      </div>*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*)}*/}

          <div><img alt="close" style={{ cursor: "pointer" }} src={closeNew}/></div>
        </div>
      </header>
      <section className={styles.main}>
        <div className={`${styles.contentWrapper} ${styles.mainContent}`}>{
          <>
            {children}
            {pathname !== "/login" && <>
							<div className={styles.footer}/>
							<div className={styles.footerContent}>

								<div className={styles.footerLogoWrapper}>
									<div className={styles.footerLogo}>
                    {Array.from(operator.name || "John Mil")[0]}
										<svg
											className={styles.logoStatus}
											xmlns="http://www.w3.org/2000/svg"
											width="9"
											height="10"
											viewBox="0 0 9 10"
											fill="none">
											<circle cx="4.5" cy="5" r="4" fill="#30BA4E" stroke="white"/>
										</svg>
									</div>
									<div>
										<div className={styles.userName}>{operator.name || "John Mil"}</div>
										<div className={styles.userEmail}>{operator.email || "JohnMil@banksi.com"}</div>
									</div>
								</div>
								<div className={styles.logoutWrapper} onClick={logOut}>
									<img src={logout} alt="logout"/>
								</div>
							</div>
						</>}
          </>}</div>
      </section>

      {/*{pathname !== '/verify' && <BottomInfo />}*/}
      <WindowFocusHandler/>
      {pathname !== "/login" && <MyModal/>}
    </div>
  );
};
