import React, { FC, useEffect } from "react";
import microMute from "../../../../assets/microMute.svg";
import endCall from "../../../../assets/endCall.svg";
import callerPhoto from "../../../../assets/callerPhoto.svg";
import { useOperatorProfileStore } from "../../../../stores/useOperatorProfile";
import { formatPhoneNumber } from "../../../../utils/helpers/formatPhoneNumber";

import styles from "./CallActions.module.scss";

export const CallActions: FC = () => {
  const getOperator = useOperatorProfileStore((state) => state.getProfile);
  const operator = useOperatorProfileStore((state) => state.operatorProfile);

  useEffect(() => {
    if (!operator) {
      getOperator();
    }
  }, []);

  return (
    <div className={styles.bottomInfoWrapper}>
      <div className={styles.phoneCallWrapper}>
        <img src={callerPhoto} alt="logo-small"/>
        <span className={styles.callerPhone}>{formatPhoneNumber(operator?.operator_phone || '9716782876')}</span>
      </div>

      <div className={styles.actionsWrapper}>
        <div className={styles.bottomInfo}>
          <img src={microMute} alt="logo-small"/>
          <span className={styles.bottomInfoText}>mute</span>
        </div>

        <div className={styles.bottomInfo}>
          <img src={endCall} alt="logo-small"/>
          <span className={styles.bottomInfoText}>end call</span>
        </div>
      </div>
    </div>
  );
};
