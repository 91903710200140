import successIconNew from '../../assets/sucsessNew.svg'
import warningNew from '../../assets/warningNew.svg';
import fraudNew from '../../assets/fraudNew.svg';
import { ActionStatuses } from '../enum/actionStatuses';

export const statusesMap = new Map([
  [
    ActionStatuses.NOT_VERIFIED,
    {
      text: 'Not verified',
      icon: warningNew,
      isSuccess: false,
      btnText: 'Try again',
      type: 'hide',
      color: '#EBB11D',
    },
  ],
  [
    ActionStatuses.FRAUD_DETECTED,
    {
      text: 'Fraud',
      icon: fraudNew,
      isSuccess: false,
      btnText: 'Try again',
      type: 'hide',
      color: '#EA424A',
    },
  ],
  [
    ActionStatuses.VERIFIED,
    {
      text: 'Verified Human',
      icon: successIconNew,
      isSuccess: true,
      btnText: 'OK',
      type: 'hide',
      color: '#227F37',
    },
  ],
  [
    ActionStatuses.TIMEOUT_EXCEEDED,
    {
      text: `Warning`,
      icon: warningNew,
      isSuccess: false,
      btnText: 'Try again',
      type: 'hide',
      color: '#EBB11D',
    },
  ],
  [
    ActionStatuses.ENROLL_NOT_PASSED,
    {
      text: 'Voice signature creation failed',
      icon: warningNew,
      isEnroll: true,
      isSuccess: false,
      btnText: 'Try again',
      type: 'show',
      color: '#EBB11D',
    },
  ],
  [
    ActionStatuses.ENROLL_PASSED,
    {
      text: `Voice signature created successfully`,
      icon: successIconNew,
      isEnroll: true,
      isSuccess: true,
      btnText: 'OK',
      type: 'show',
      color: '#227F37',
    },
  ],
  [
    ActionStatuses.ERROR,
    {
      text: 'Something went wrong!',
      subText: 'Please try again',
      icon: fraudNew,
      color: '#EA424A',
    },
  ],
]);
