import React, { FC, useEffect } from 'react';
// import company from '../../../../assets/company.svg';

import logo from '../../../../assets/corsound-logo-no-text.svg';
import background from '../../../../assets/background-main.svg';

import { useOperatorProfileStore } from '../../../../stores/useOperatorProfile';
import styles from './mainScreen.module.scss';

export const MainScreen: FC = () => {
  const getOperator = useOperatorProfileStore((state) => state.getProfile);
  const operatorName = useOperatorProfileStore((state) => state.operatorProfile.name);
  // const operatorCompany = useOperatorProfileStore((state) => state.operatorProfile.org_name);

  useEffect(() => {
    if (!operatorName) {
      getOperator();
    }
  }, []);

  return (
    <div className={styles.mainWrapper}>
      <img src={background} className={styles.backgroundDots} alt="background"/>
      <div className={styles.mainInfo}>
        <img className={styles.logo} src={logo} alt="logo" />
        <h2 className={styles.description}>Deefake Detection System</h2>
        {/*<span className={styles.titleUser}>Hello {operatorName}</span>*/}
        <div className={styles.companyWrapper}>
          {/*<img className={styles.company} src={company} />*/}
          {/*<span className={styles.titleCompany}>{operatorCompany}</span>*/}
          <span className={styles.titleCompany}>By Corsound AI</span>
        </div>
        <div className={styles.title}>{`Ready for the next call`}</div>
      </div>
    </div>
  );
};
