import cn from "classnames";
import React, { FC, useEffect } from "react";
// import pulseRight from "../../../../assets/sound_right.svg";
// import pulseLeft from "../../../../assets/sound-left.svg";
// import pulse from "../../../../assets/pulse.svg";
// import voiceGray from '../../../../assets/voice_gray.svg';
// import notVoiceGray from '../../../../assets/voice-not.svg';
// import update from '../../../..//assets/update.svg';
// import { useNavigate } from 'react-router-dom';
import { useCallInfoStore } from "../../../../stores/useCallInfo";
import { useOperatorProfileStore } from "../../../../stores/useOperatorProfile";
import Button from "../Button/Button";
// import { useVadInfoStore } from '../../../../stores/useVadllInfo';
// import { TaskType } from '../../../../utils/enum/taskType';
// import { createdAt } from '../../../../utils/helpers/createdAt';
// import { emitCommand, EmitCommandParams } from '../../../../utils/helpers/emitCommand';
// import Button from '../Button/Button';

import styles from "./CallerInfo.module.scss";
// import { useConnectionStore } from '../../../../stores/useConnection';
// import { Socket } from 'socket.io-client';

type CallerInfoProps = {
  animated?: boolean;
  isFinished: boolean;
};
// isFinished
const CallerInfo: FC<CallerInfoProps> = () => {
  // const navigate = useNavigate();
  //
  // const socket = useConnectionStore((state) => state.socket as Socket);

  // const callerPhoneNumber = useCallInfoStore((state) => state.call_data.from);
  // const clientName = useCallInfoStore((state) => state.call_data.client);
  const callerId = useCallInfoStore((state) => state.call_data.client.claim_id);
  // const voiceSignature = useCallInfoStore((state) => state.call_data.client.dt_signature);
  // const refreshVadTotalSeconds = useVadInfoStore((state) => state.refreshVadTotalSeconds);
  //
  // const callId = useCallInfoStore((state) => state.call_id);
  // const clientId = useCallInfoStore((state) => state.call_data.client.client_id);

  const getOperator = useOperatorProfileStore((state) => state.getProfile);
  const operator = useOperatorProfileStore((state) => state.operatorProfile);
  // const operatorCompany = useOperatorProfileStore((state) => state.operatorProfile.org_name);

  useEffect(() => {
    if (!operator) {
      getOperator();
    }
  }, []);
  // const updateSignature = () => {
  //   localStorage.setItem('action', 'update');
  //   const params: EmitCommandParams = {
  //     socket,
  //     task_type: TaskType.ENROLL,
  //     call_id: callId,
  //     client_id: clientId,
  //   };
  //
  //   refreshVadTotalSeconds();
  //   emitCommand(params);
  //   navigate('/enroll');
  // };

  return (
    <div className={styles.callerWrapper}>
      <div className={styles.callerInfo}>
        <div className={styles.infoWrapper}>
          <div className={styles.infoTitle}>name</div>
          <div className={styles.infoText}>{operator.name || 'ben Smith'}</div>
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.infoTitle}>ID</div>
          <div className={styles.infoText}>{callerId || '90889887'}</div>
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.infoTitle}>iD account</div>
          <div className={styles.infoText}>{callerId || '90889887'}</div>
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.infoTitle}>type</div>
          <div className={cn(styles.infoText, styles.infoTextFilled)}>premium</div>
        </div>
        {/*<div className={styles.timerWrapper}>*/}
        {/*{animated ? (*/}
        {/*  <div className={styles.lineWrapper}>*/}
        {/*    <img className={styles.lineTwo} src={pulseLeft} alt="part one" />*/}
        {/*    <img className={styles.lineOne} src={pulseRight} alt="part two" />*/}
        {/*  </div>*/}
        {/*) : (*/}
        {/*  <img src={pulse} alt="pulse" />*/}
        {/*)}*/}
        {/*  //TODO Add total call time after back-end return them*/}
        {/*<div>{duration}</div>*/}
        {/* <p>{vadSeconds}</p> */}
        {/*</div>*/}
      </div>
      <Button styled={styles.infoButton} onClick={() => { console.log("") }} titleBtn="enter account"/>

      {/*<div className={styles.callerUpdate}>*/}
      {/*  <div className={styles.signatureWrapper}>*/}
      {/*    <img src={voiceSignature ? voiceGray : notVoiceGray} alt="voice"></img>*/}
      {/*    <div className={styles.signatureData}>*/}
      {/*      {voiceSignature ? `Voice signature created on:` : 'Voice signature not created'}*/}
      {/*      <br />*/}
      {/*      {voiceSignature && createdAt(voiceSignature)}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  {isFinished && (*/}
      {/*    <Button*/}
      {/*      styled={styles.styled}*/}
      {/*      onClick={updateSignature}*/}
      {/*      titleBtn={*/}
      {/*        <>*/}
      {/*          <img src={update} alt="update"></img>*/}
      {/*          <div>Update</div>*/}
      {/*        </>*/}
      {/*      }*/}
      {/*    ></Button>*/}
      {/*  )}*/}
      {/*</div>*/}

      <div className={styles.line}></div>
    </div>
  );
};

export default CallerInfo;
